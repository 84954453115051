const loadBlock = ( callback, blockName, sectionSelector ) => {


	$( document ).ready( function() {
		if ( window.acf && window.acf.isGutenberg() ) {
			// Site Editor
			window.acf.addAction(
				`render_block_preview/type=${ blockName }`,
				themeUtils.adminInitializeBlock( callback, sectionSelector )
			);
		} else {
			// Front-end
			callback( $( sectionSelector ) );
		}
	} );
};

export default loadBlock;
