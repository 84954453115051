import { isMobile } from "../utils/is-mobile";

( async function renderInfiniteSlider() {
	await import( "../infiniteslidev2.min.js" );
} )( jQuery );

const initializeInfiniteSlider = ( slider, direction ) => {
	const $infiniteslider = slider;
	let windowWidth = window.innerWidth;
	function getCloneCount( $container ) {
		const $slide = $container.find( ".slide" );
		const slideWidth = $slide.outerWidth();
		const sliderWidth = $container.outerWidth();
		const numberOfSlides = $slide.length;
		const requiredNumberOfSlides = Math.ceil( sliderWidth / slideWidth ) * 2;
		const result = Math.ceil( requiredNumberOfSlides / numberOfSlides ) - 1;

		return result < 1 ? 1 : result;
	}

	function destroyInfiniteslide( $infiniteslide ) {
		$infiniteslide.attr( "style", "" );
		$infiniteslide.find( ".infiniteslide_clone" ).remove();

		if ( $infiniteslide.parent().hasClass( "infiniteslide_wrap" ) ) {
			$infiniteslide.unwrap();
		}
	}

	$infiniteslider.each( function() {
		const $container = $( this );
		let tryCount = 0;
		destroyInfiniteslide( $infiniteslider );
		function sliderCall() {
			tryCount++;

			if ( typeof $container.infiniteslide !== "undefined" ) {
				$container.infiniteslide( {
					"speed": 50, //speed this is px/min
					"direction": direction, //choose  up/down/left/right
					"pauseonhover": !isMobile.any(), //if true,stop onmouseover
					"responsive": false, //width/height recalculation on window resize. child element""s width/height define %/vw/vh,this set true.
					"clone": getCloneCount( $container ) + 2 //if child elements are too few (elements can""t "infinite"), set 2 or over.
				} );

				$( window ).trigger( "custom-lozad:update" );
			} else {
				if ( tryCount < 20 ) {
					setTimeout( sliderCall, 2000 );
				}
			}
		}

		if ( $container.outerWidth() <= $container.find( ".slide" ).length * $container.find( ".slide:first-child" ).outerWidth() ) {
			sliderCall();
		}

		$( window ).on( "resize", function() {
			if ( windowWidth !== window.innerWidth ) {
				windowWidth = window.innerWidth;
				destroyInfiniteslide( $infiniteslider );
				if ( $container.outerWidth() <= $container.find( ".slide" ).length * $container.find( ".slide:first-child" ).outerWidth() ) {
					sliderCall();
				}
			}
		} );
	} );
};

export default initializeInfiniteSlider;
